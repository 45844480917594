<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" :class="['fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6', boxVerticalPosition]" style="z-index: 100">
        <div :class="['w-full flex flex-col items-center space-y-4', boxHorizontalPosition]">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition enter-active-class="transform ease-out duration-300 transition"
                        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                        leave-active-class="transition ease-in duration-100"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                <div v-if="visible" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="p-4">
                        <div class="flex items-center">
                            <div class="w-0 flex-1 flex justify-between">
                                <p class="w-0 flex-1 text-sm font-medium text-gray-900">
                                    {{ text }}
                                </p>
                            </div>
                            <div class="ml-4 flex-shrink-0 flex">
                                <i :class="['text-lg text-gray-400 hover:text-gray-500 cursor-pointer', closeIcon]" @click="visible = false"/>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import {INFO_BOX_TIMEOUT} from "../master/constants";

export default {
    name: "info-box",
    data(){
        return {
            visible: this.$props.show
        }
    },
    props: {
        show: { type: Boolean, default: false},
        text: { type: String, default: "Something went wrong."},
        icon: { type: String, default: 'fal fa-exclamation-triangle'},
        closeIcon: { type: String, default: 'fal fa-times'},
        horizontalPosition: { type: String, default: 'right'},
        verticalPosition: { type: String, default: 'top'},
    },

    watch: {
        show: function(newVal) { // watch it
            this.visible = newVal
            if(newVal){
                setTimeout(() => this.visible = false, INFO_BOX_TIMEOUT)
            }
        }
    },
    computed: {
        boxHorizontalPosition() {
            return this.$props.horizontalPosition.toLowerCase() === 'left' ? 'sm:items-start' : 'sm:items-end'
        },
        boxVerticalPosition() {
            return this.$props.verticalPosition.toLowerCase() === 'bottom' ? 'sm:items-end' : 'sm:items-start';
        }
    }
}
</script>