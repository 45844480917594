<template>
    <div class="flex flex-col w-full">
        <div :class="['flex flex-col', loading ? 'cursor-default pointer-events-none opacity-50' : '']">
            <label v-if="label" :class="['block text-sm font-medium mb-1', hasError ? 'text-red-700' : 'text-gray-700']">{{ label }} {{ required ? '*' : '' }}</label>
            <t-rich-select
                v-model="selectedOption"
                :placeholder="loading ? '...' : placeholder"
                :options="options"
                :disabled="loading"
                :variant="hasError ? 'danger' : ''"
                :class="['custom-select', className, loading ? 'cursor-default pointer-events-none opacity-25' : 'w-full sm:max-w-full']"
            >
                <template
                    slot="label"
                    slot-scope="{ className, option }"
                >
                    <div :class="[className, 'font-normal leading-5 sm:text-sm py-0.5 capitalize text-black']">{{ option.raw.text.toLowerCase() }}</div>

                </template>

                <template
                    slot="option"
                    slot-scope="{ index, isHighlighted, isSelected, className, option }"
                >
                    <div :class="['capitalize', className]">
                        {{ option.raw.text.toLowerCase() }}
                    </div>
                </template>
            </t-rich-select>
            <div v-if="loading" class="flex items-center ml-2">
                <span class="fas fa-spinner-third fa-spin mr-2"></span>
                <translate>Fetching</translate>...
            </div>
        </div>
        <span v-if="hasError" class="mt-1 text-red-700 text-xs"><i class="fal fa-exclamation-triangle mr-2" />{{ error }}</span>
    </div>
</template>

<script>

export default {
    name: "base-select",
    props: {
        className: {type: String, default: "max-w-lg block w-full shadow-sm focus:outline-none sm:max-w-xs sm:text-sm border-gray-300 rounded-md"},
        selected: {type: String},
        label: {type: String, default: ''},
        error: {type: String, default: ''},
        placeholder: {type: String, default: 'Please select'},
        required: {type: Boolean, default: false},
        loading: {type: Boolean, default: false},
        options: { type: Array,  default: () => []} // Array of objects [{value: '', text: ''}, {value: '', text: ''}, ...]
    },
    data() {
        return {
            selectedOption: ''
        }
    },

    watch: {
        selectedOption() {
            if (this.selectedOption !== this.$props.selected) {
                this.$emit('on-change', this.selectedOption)
            }
        },

        selected(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.selectedOption = newVal
            }
        }
    },

    computed: {
        hasError() {
            return this.$props.error !== '' && this.$props.error !== null && this.$props.error !== undefined;
        },
    },

    created() {
        if (this.$props.selected) {
            this.selectedOption = this.$props.selected;
        }
    }
}
</script>

<style scoped>

.custom-select button{
    height: 52px !important;
}

</style>