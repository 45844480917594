var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full"},[_c('div',{class:['flex flex-col', _vm.loading ? 'cursor-default pointer-events-none opacity-50' : '']},[(_vm.label)?_c('label',{class:['block text-sm font-medium mb-1', _vm.hasError ? 'text-red-700' : 'text-gray-700']},[_vm._v(_vm._s(_vm.label)+" "+_vm._s(_vm.required ? '*' : ''))]):_vm._e(),_c('t-rich-select',{class:['custom-select', _vm.className, _vm.loading ? 'cursor-default pointer-events-none opacity-25' : 'w-full sm:max-w-full'],attrs:{"placeholder":_vm.loading ? '...' : _vm.placeholder,"options":_vm.options,"disabled":_vm.loading,"variant":_vm.hasError ? 'danger' : ''},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var className = ref.className;
var option = ref.option;
return [_c('div',{class:[className, 'font-normal leading-5 sm:text-sm py-0.5 capitalize text-black']},[_vm._v(_vm._s(option.raw.text.toLowerCase()))])]}},{key:"option",fn:function(ref){
var index = ref.index;
var isHighlighted = ref.isHighlighted;
var isSelected = ref.isSelected;
var className = ref.className;
var option = ref.option;
return [_c('div',{class:['capitalize', className]},[_vm._v(" "+_vm._s(option.raw.text.toLowerCase())+" ")])]}}]),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}}),(_vm.loading)?_c('div',{staticClass:"flex items-center ml-2"},[_c('span',{staticClass:"fas fa-spinner-third fa-spin mr-2"}),_c('translate',[_vm._v("Fetching")]),_vm._v("... ")],1):_vm._e()],1),(_vm.hasError)?_c('span',{staticClass:"mt-1 text-red-700 text-xs"},[_c('i',{staticClass:"fal fa-exclamation-triangle mr-2"}),_vm._v(_vm._s(_vm.error))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }