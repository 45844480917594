<template>
    <div>
        <autralis-error-comp :show="error !== null" :text="error"/>
        <div v-if="saving" class="flex items-center">
            <span class="fas fa-spinner-third fa-spin mr-2"></span>
            <translate>Saving</translate>
            ...
        </div>
        <div v-else :class="config.wrapper">
            <div v-for="(stateTrigger, index) in stateTriggers"
                 :key="stateTrigger.trigger"
                 :class="[config.button, index > 0 && config.nextButton]"
                 @click="changeStatus(stateTrigger.trigger)">
                <div class="capitalize"><i :class="[stateTrigger.icon, 'mr-2']"/>{{ stateButtonLabel(stateTrigger.trigger) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import ErrorBox from "@/components/error-box";
import {generateTriggers, STATE_TRIGGERS} from "../views/Home/states-and-triggers";
import {uniq} from "lodash";

const initConfigClassName = {
    'wrapper': 'flex flex-col xl:flex-row items-start xl:items-center',
    'button': 'flex items-center cursor-pointer text-gray-500 hover:text-gray-800 font-medium',
    'nextButton': 'mt-4 xl:mt-0 ml-0 xl:ml-4'
};

export default {
    name: "StatusButtons",
    props: {
        states: {
            type: Array,
            default: () => []
        },
        classNameConfig: {
            type: Object,
            default: () => initConfigClassName
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            stateTriggers: []
        }
    },

    watch: {
        states() {
            this.setTriggers()
        }
    },

    computed: {
        config() {
            return {...initConfigClassName, ...this.$props.classNameConfig}
        },
    },

    methods: {
        setTriggers() {
            this.stateTriggers = generateTriggers(uniq(this.states))
        },
        stateButtonLabel(trigger) {
            return STATE_TRIGGERS[trigger] ? STATE_TRIGGERS[trigger].toLowerCase() : ''
        },
        async changeStatus(trigger) {
            this.$emit('on-change', trigger)
        },
    },

    created() {
        this.setTriggers()
    },

    components: {
        'autralis-error-comp': ErrorBox,
    }
}
</script>

<style scoped>

</style>